<template>
  <loading-screen v-if="is_loading_blind || is_loading_fabrics || is_loading_articles"></loading-screen>
  <div v-else class="page-container">
    <div v-if="blind_details" class="header-container">
      <div class="title-container">
        <router-link :to="{name: 'calculators-index'}">
          <font-awesome-icon :icon="['far', 'arrow-circle-left']"/>
        </router-link>
        <h1>{{ blind_details.attributes.name }}</h1>
      </div>
      <div v-if="!$route.query.token && !$route.query.quote && !$route.query.cart_order"
           class="lg:ml-auto pt-4 lg:pt-0 -mt-1 mr-6 w-32">
        <FormInputText v-model="$v.blind_quantity.$model" :has-error="$v.blind_quantity.$error"
                       :label="$t('calculators.quantity')" :min="1"
                       :placeholder="$t('calculators.quantity')" borderColor="primary" identifier="blind_quantity"
                       type="number">
          <template v-slot:errors>
            <p v-if="!$v.blind_quantity.required">
              {{ $t('validation.x_is_required', {x: $t('calculators.quantity')}) }}
            </p>
            <p v-else-if="!$v.blind_quantity.minValue">
              {{ $t('validation.minimum_quantity', {x: '1'}) }}
            </p>
          </template>
        </FormInputText>
      </div>
      <div :class="[$route.query.token ? 'pt-4 lg:ml-auto lg:pt-0' : '']" class="price-container">
        <div class="price">
          <p class="px-4" style="min-width: 9rem;">€{{ totalCost }}<span v-if="blind_quantity > 1 && totalCost"> (Total: €{{ totalCostWithQuantity }})</span>
          </p>
        </div>
        <Button :class="{'spinner-black': is_adding_item}" :onclick="addToCart" className="--primary --small"
                type="submit">
          {{
            $route.query.token ? $t('calculators.update') : ($route.query.quote ? $t('calculators.add_to_quote') : $t('calculators.add_to_cart'))
          }}
        </Button>
      </div>
    </div>

    <div class="form-container">
      <h1 class="form-title">{{ $t('calculators.measurements') }}</h1>

      <FormGroupFour class="form main-form">
        <FormInputText v-model="$v.blind.width.$model" :has-error="$v.blind.width.$error"
                       :label="$t('calculators.aperture_width') + ' (cm)'"
                       :max="240"
                       :placeholder="$t('calculators.aperture_width')" borderColor="primary" identifier="width"
                       type="number">
          <template v-slot:errors>
            <p v-if="!$v.blind.width.required">
              {{ $t('validation.x_is_required', {x: $t('calculators.aperture_width')}) }}
            </p>
          </template>
        </FormInputText>

        <FormInputText v-model="$v.blind.drop.$model" :has-error="$v.blind.drop.$error"
                       :label="$t('calculators.aperture_drop') + ' (cm)'"
                       :placeholder="$t('calculators.aperture_drop')"
                       borderColor="primary" identifier="drop"
                       type="number">
          <template v-slot:errors>
            <p v-if="!$v.blind.drop.required">
              {{ $t('validation.x_is_required', {x: $t('calculators.aperture_drop')}) }}
            </p>
          </template>
        </FormInputText>
        <FormInputText v-model="slatQtyUI" :disabled="true" :label="$t('calculators.slat_qty')"
                       :placeholder="$t('calculators.slat_qty')"
                       class="slat-qty-container" identifier="slat-qty"
                       type="number"/>
        <FormInputSelect v-model="$v.blind.aperture_type.$model"
                         :display-custom-label="(row) => row.charAt(0).toUpperCase() + row.slice(1)"
                         :has-error="$v.blind.aperture_type.$error"
                         :label="$t('calculators.aperture_type')" :options="apertureTypeOptions"
                         :placeholder="$t('calculators.aperture_type')" identifier="aperture_type"
                         track-by="type">
          <template v-slot:errors>
            <p v-if="!$v.blind.aperture_type.required">
              {{ $t('validation.x_is_required', {x: $t('calculators.aperture_type')}) }}
            </p>
          </template>
        </FormInputSelect>
      </FormGroupFour>

      <FormGroupFour class="form main-form">
        <FormInputSelect v-model="$v.blind.recess_type.$model" :has-error="$v.blind.recess_type.$error"
                         :label="$t('calculators.recess_type')"
                         :options="recessTypeOptions"
                         :placeholder="$t('calculators.recess_type')" identifier="recess-type"
                         type="number" @input="onSelectRecessType">
          <template v-slot:errors>
            <p v-if="!$v.blind.recess_type.required">
              {{ $t('validation.x_is_required', {x: $t('calculators.recess_type')}) }}
            </p>
          </template>
        </FormInputSelect>

        <FormInputSelect v-if="blind.recess_type === 'Inside recess'" v-model="$v.blind.width_clearance.$model"
                         :disabled="blind.recess_type === 'Outside recess'"
                         :has-error="$v.blind.width_clearance.$error"
                         :label="$t('calculators.width_clearance')"
                         :options="clearanceOptions" :placeholder="$t('calculators.width_clearance')"
                         identifier="width-clearance"
                         type="number">
          <template v-slot:errors>
            <p v-if="!$v.blind.width_clearance.required">
              {{ $t('validation.x_is_required', {x: $t('calculators.width_clearance')}) }}
            </p>
          </template>
        </FormInputSelect>

        <FormInputText v-if="blind.recess_type === 'Outside recess'" v-model="$v.blind.overlap_width.$model"
                       :has-error="$v.blind.overlap_width.$error"
                       :label="$t('calculators.outside_overlap_width') + ' (cm)'"
                       :max="240"
                       :placeholder="$t('calculators.outside_overlap_width')" borderColor="primary" identifier="width"
                       type="number">
          <template v-slot:errors>
            <p v-if="!$v.blind.overlap_width.required">
              {{ $t('validation.x_is_required', {x: $t('calculators.outside_overlap_width')}) }}
            </p>
          </template>
        </FormInputText>

        <FormInputText v-if="blind.recess_type === 'Outside recess'" v-model="$v.blind.overlap_drop.$model"
                       :has-error="$v.blind.overlap_drop.$error"
                       :label="$t('calculators.outside_overlap_drop') + ' (cm)'"
                       :max="240"
                       :placeholder="$t('calculators.outside_overlap_drop')" borderColor="primary" identifier="width"
                       type="number">
          <template v-slot:errors>
            <p v-if="!$v.blind.overlap_drop.required">
              {{ $t('validation.x_is_required', {x: $t('calculators.outside_overlap_drop')}) }}
            </p>
          </template>
        </FormInputText>
      </FormGroupFour>

      <FormGroupFour class="form main-form">
        <FormInputSelect v-model="$v.blind.cord_side.$model" :has-error="$v.blind.cord_side.$error"
                         :label="$t('calculators.cord_side')"
                         :options="cordSideOptions" :placeholder="$t('calculators.cord_side')"
                         identifier="cord-side" type="number"
                         @input="onCordSideSelect">
          <template v-slot:errors>
            <p v-if="!$v.blind.cord_side.required">
              {{ $t('validation.x_is_required', {x: $t('calculators.cord_side')}) }}
            </p>
          </template>
        </FormInputSelect>

        <FormInputSelect v-model="$v.blind.wand_side.$model"
                         :disabled="blind.cord_side === 'Combination LH' || blind.cord_side === 'Combination RH'"
                         :has-error="$v.blind.wand_side.$error"
                         :label="$t('calculators.wand_side')" :options="wandSideOptions"
                         :placeholder="$t('calculators.wand_side')"
                         identifier="wand-side" type="number"
                         @input="onWandSideSelect">
          <template v-slot:errors>
            <p v-if="!$v.blind.wand_side.required">
              {{ $t('validation.x_is_required', {x: $t('calculators.wand_side')}) }}
            </p>
          </template>
        </FormInputSelect>
      </FormGroupFour>

      <div class="divider"></div>

      <div class="form-wrapper form">
        <FormGroupTwo class="textboxes-container">
          <FormInputText v-model="$v.blind.tile_cut_width.$model" :disabled="blind.recess_type === 'Outside recess'"
                         :has-error="$v.blind.tile_cut_width.$error"
                         :label="$t('calculators.tile_cut_width') + ' (cm)'"
                         :placeholder="$t('calculators.tile_cut_width')" identifier="tile-cut-width"
                         type="number">
            <template v-slot:errors>
            </template>
          </FormInputText>

          <FormInputText v-model="$v.blind.tile_cut_height.$model" :disabled="blind.recess_type === 'Outside recess'"
                         :has-error="$v.blind.tile_cut_height.$error"
                         :label="$t('calculators.tile_cut_height') + ' (cm)'"
                         :placeholder="$t('calculators.tile_cut_height')"
                         identifier="tile-cut-height"
                         type="number">
            <template v-slot:errors>
            </template>
          </FormInputText>

          <FormInputSelect v-model="$v.blind.tile_cut_width_clearance.$model"
                           :disabled="blind.recess_type === 'Outside recess'"
                           :has-error="$v.blind.tile_cut_width_clearance.$error"
                           :label="$t('calculators.tile_cut_width_clearance')"
                           :options="tileCutWidthClearanceOptions"
                           :placeholder="$t('calculators.tile_cut_width_clearance')"
                           identifier="tile-cut-width-clearance"
                           type="number">
            <template v-slot:errors>
              <p v-if="!$v.blind.tile_cut_width_clearance.required">
                {{ $t('validation.x_is_required', {x: $t('calculators.tile_cut_width_clearance')}) }}
              </p>
            </template>
          </FormInputSelect>
        </FormGroupTwo>

        <FormInputText v-model="note" :label="$t('calculators.note')" :largeTextarea="true"
                       :placeholder="$t('calculators.note')"
                       :useTextarea="true" class="note-field"
                       identifier="note"></FormInputText>
      </div>
    </div>

    <div class="form-container cards">
      <h1 class="form-title">{{ $t('calculators.slats') }}</h1>
      <div class="item-container">
        <div v-for="fabric in fabrics" class="item-card-container">
          <div :class="{'selected': fabric_selected === fabric}" class="item-card"
               @click="onFabricSelect(fabric)">
            <h1 class="item-card-title">{{ fabric.attributes.name }}</h1>
          </div>
        </div>
      </div>
    </div>

    <div v-if="fabric_selected" class="form-container cards">
      <h1 class="form-title">{{ $t('calculators.slat_colours') }}</h1>
      <div class="item-container">
        <div v-for="colour in JSON.parse(fabric_selected.attributes.colors)" class="item-card-container">
          <div :class="{'selected': colour_selected === colour}" class="item-card"
               @click="onColourSelect(colour)">
            <h1 class="item-card-title">{{ colour }}</h1>
          </div>
        </div>
      </div>
      <FormInputText v-show="colour_selected === 'Custom Colour'" v-model="$v.custom_colour_code.$model"
                     :has-error="$v.custom_colour_code.$error"
                     :label="$t('calculators.custom_colour_code')" :placeholder="$t('calculators.custom_colour_code')"
                     class="custom-colour-field"
                     identifier="custom-colour-code"
                     type="text">
        <template v-slot:errors>
          <p v-if="!$v.custom_colour_code.required">
            {{ $t('validation.x_is_required', {x: $t('calculators.custom_colour_code')}) }}
          </p>
        </template>
      </FormInputText>
    </div>

    <div v-if="motors.length || controller_types.length" class="form-container cards">
      <h1 class="form-title">Home Automation</h1>
      <div class="category-container">
        <h2>Motors</h2>

        <div class="item-container">
          <div v-for="motor in motors" class="item-card-container">
            <div :class="{'selected': motor === motor_selected, 'disabled': motor.disabled}" class="item-card"
                 @click="onMotorSelected(motor)">
              <h1 class="item-card-title">{{ motor.attributes.name }}</h1>
            </div>
          </div>
        </div>

        <div v-if="controller_types.length > 0" class="divider"></div>

        <h2>Controller Type</h2>

        <div class="item-container">
          <div v-for="controller_type in controller_types" class="item-card-container">
            <div :class="{'selected': controller_type === controller_type_selected}" class="item-card"
                 @click="onControllerTypeSelected(controller_type)">
              <h1 class="item-card-title">{{ controller_type.attributes.name }}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormInputText from "@/components/form/FormInputText";
import FormGroupTwo from "../../components/form/FormGroupTwo";
import Button from "@/components/Button";
import FormGroupThree from "@/components/form/FormGroupThree";
import FormInputSelect from "@/components/form/FormInputSelect";
import FormGroupFour from "@/components/form/FormGroupFour";
import LoadingScreen from "@/components/LoadingScreen";
import {minValue, required, requiredIf} from "vuelidate/lib/validators";
import {createArrayPayloadFromBlind} from "@/utils/BlindUtils";

export default {
  name: "VenetianBlind",
  components: {LoadingScreen, FormGroupFour, FormInputSelect, FormGroupThree, Button, FormInputText, FormGroupTwo},
  data() {
    return {
      blind: {
        width: null,
        drop: null,
        overlap_width: null,
        overlap_drop: null,
        aperture_type: null,
        recess_type: null,
        width_clearance: null,
        cord_side: null,
        wand_side: null,
        tile_cut_width: null,
        tile_cut_height: null,
        tile_cut_width_clearance: null,
      },
      blind_quantity: 1,
      note: null,

      apertureTypeOptions: ['door', 'window'],
      recessTypeOptions: ['Outside recess', 'Inside recess'],
      clearanceOptions: ['0.5 cm', '1 cm', '1.5 cm', '2 cm'],
      cordSideOptions: ['Right', 'Combination LH', 'Combination RH'],
      wandSideOptions: ['Left', 'Combination LH', 'Combination RH'],
      tileCutWidthClearanceOptions: ['0.5 cm', '1 cm', '1.5 cm', '2 cm'],
      aluminium_slat_colours: [],
      per_cm_items: [],
      per_slat_items: [],
      per_unit_items: [],
      per_hole_items: [],
      fabric: {
        attributes: {
          name: 'Blackout Fabric',
          code: 'Tex2',
          price: {
            currency: 'EUR',
            value: 1
          },
          conversion_rate: 0.857,
          shipping_cost: 0.1,
          markup: 0.1,
          vat: 0.18,
          multiplier: 0.01,
        }
      },
      fabrics: [],
      fabric_selected: null,

      colour_selected: null,
      custom_colour_code: null,
      motors: [],
      controller_types: [],
      motor_selected: null,
      controller_type_selected: null,

      prev_cord_side: null,
      prev_wand_side: null,

      blind_details: null,
      is_loading_blind: false,
      is_loading_fabrics: false,
      is_loading_articles: false,
      is_adding_item: false,
    }
  },
  validations: {
    blind: {
      width: {
        required,
      },
      drop: {
        required,
      },
      aperture_type: {required},

      recess_type: {required},
      width_clearance: {
        required: requiredIf(function () {
          return this.blind.recess_type === 'Inside recess';
        })
      },
      cord_side: {required},
      wand_side: {required},
      tile_cut_width: {},
      tile_cut_height: {},
      tile_cut_width_clearance: {
        required: requiredIf(function () {
          return this.blind.tile_cut_width;
        })
      },
      overlap_width: {
        required: requiredIf(function () {
          return this.blind.recess_type === 'Outside recess';
        })
      },
      overlap_drop: {
        required: requiredIf(function () {
          return this.blind.recess_type === 'Outside recess';
        })
      },
    },
    blind_quantity: {required: required, minValue: minValue(1)},
    custom_colour_code: {
      required: requiredIf(function () {
        return this.colour_selected === 'Custom Colour';
      })
    }
  },
  watch: {
    blind: {
      handler(newValue, oldValue) {
        if (newValue.recess_type === 'Outside recess') {
          this.blind.width_clearance = null;
        }
        if (newValue.recess_type === 'Inside recess') {
          this.blind.overlap_drop = null;
          this.blind.overlap_width = null;
        }
      },
      deep: true
    }
  },
  methods: {
    onCordSideSelect() {
      if (this.blind.cord_side === 'Combination LH') {
        this.blind.wand_side = 'Combination LH';
      }

      if (this.blind.cord_side === 'Combination RH') {
        this.blind.wand_side = 'Combination RH';
      }

      if ((this.prev_cord_side === 'Combination LH' || this.prev_cord_side === 'Combination RH') && this.blind.cord_side === 'Right')
        this.blind.wand_side = null;

      this.prev_cord_side = this.blind.cord_side;
      this.prev_wand_side = this.blind.wand_side;
    },
    onWandSideSelect() {
      if (this.blind.wand_side === 'Combination LH') {
        this.blind.cord_side = 'Combination LH';
      }

      if (this.blind.wand_side === 'Combination RH') {
        this.blind.cord_side = 'Combination RH';
      }

      if ((this.prev_wand_side === 'Combination LH' || this.prev_wand_side === 'Combination RH') && this.blind.wand_side === 'Left')
        this.blind.cord_side = null;

      this.prev_cord_side = this.blind.cord_side;
      this.prev_wand_side = this.blind.wand_side;
    },
    onFabricSelect(fabric) {
      this.fabric_selected = fabric;
    },
    onColourSelect(colour) {
      this.colour_selected = colour;
    },
    onMotorSelected(motor) {
      if (motor === this.motor_selected)
        this.motor_selected = null;
      else
        this.motor_selected = motor;
    },
    onControllerTypeSelected(controller_type) {
      if (controller_type === this.controller_type_selected)
        this.controller_type_selected = null;
      else
        this.controller_type_selected = controller_type;
    },
    onSelectRecessType(recess) {
      if (recess === 'Outside recess') {
        this.blind.tile_cut_width = null
        this.blind.tile_cut_height = null
        this.blind.tile_cut_width_clearance = null
      } else if (recess === 'Inside recess') {
      }
    },
    unitsCM2(fabric) {
      return +(Math.round((this.apertureDropWithOverlap * this.apertureWidthWithOverlap * fabric.attributes.multiplier) + "e+2") + "e-2");
    },
    async retrieveBlind() {
      this.is_loading_blind = true;

      await this.$axios.get(`blinds/${this.$route.query.id}`).then(({data}) => {
        this.blind_details = data.data;
        this.is_loading_blind = false;
      }).catch(e => {
        this.is_loading_blind = false;

        this.$notify({
          text: this.$larerror(e.response.data.errors, this.$t('calculators.error_failed_retrieve_blind')),
          type: 'error',
        });
      });
    },
    async retrieveFabrics() {
      this.is_loading_fabrics = true;

      await this.$axios.get(`blinds/${this.$route.query.id}/fabrics`).then(({data}) => {
        this.fabrics = data.data.sort((a, b) => a.attributes.order - b.attributes.order);
        this.is_loading_fabrics = false;
      }).catch(e => {
        this.is_loading_fabrics = false;

        this.$notify({
          text: this.$larerror(e.response.data.errors, this.$t('calculators.error_failed_retrieve_fabrics')),
          type: 'error',
        });
      });
    },
    async retrieveArticles() {
      this.is_loading_articles = true;

      await this.$axios.get(`blinds/${this.$route.query.id}/articles/list`).then(({data}) => {
        this.articles = data.data;

        this.articles.forEach(article => {
          if (article.attributes.unit === 'per_cm') {
            this.per_cm_items.push(article);
          }

          if (article.attributes.unit === 'per_unit') {
            this.per_unit_items.push(article);
          }

          if (article.attributes.unit === 'per_hole') {
            this.per_hole_items.push(article);
          }

          if (article.relationships.category.data && article.relationships.category.data.attributes && article.relationships.category.data.attributes.name === 'Motors')
            this.motors.push(article);

          if (article.relationships.category.data && article.relationships.category.data.attributes && article.relationships.category.data.attributes.name === 'Controller Types')
            this.controller_types.push(article);
        });

        this.is_loading_articles = false;
      }).catch(e => {
        this.is_loading_articles = false;

        this.$notify({
          text: this.$larerror(e.response.data.errors, this.$t('calculators.error_failed_retrieve_articles')),
          type: 'error',
        });
      });
    },
    async getOrder() {
      let order = 0;

      if (!this.$route.query.token) {
        if (this.$route.query.quote) {
          let order = 0;

          await this.$axios.get(`/quotes/${this.$route.query.quote}`).then(({data}) => {
            if (data.data.relationships.blinds.data.length)
              data.data.relationships.blinds.data.forEach(blind => {
                if (blind.attributes.order > order)
                  order = blind.attributes.order;
              })
          });

          return order += 1;
        } else if (this.cart) {
          this.cart.relationships.blinds.data.forEach(blind => {
            if (blind.attributes.order > order)
              order = blind.attributes.order;
          })
        }

        return order += 1;
      } else {
        return order = this.$route.query.cart_order ? this.$route.query.cart_order : 0;
      }
    },
    async updateCartOrder(payload) {
      await this.$axios.put(`carts/${this.$route.query.cart}/blinds/detach`, {token: this.$route.query.token})
          .then(({data}) => {
          })
          .catch(e => {
            this.$notify({
              title: this.$t('error'),
              text: this.$larerror(e.response.data, this.$t('cart.error_remove_from_cart')),
              type: 'error',
            });
          });

      await this.$axios.put(`carts/${this.$route.query.cart}/blinds/${this.$route.query.id}/attach`, payload).then(({data}) => {
        this.is_adding_item = false;
      }).catch(e => {
        this.is_adding_item = false;

        this.$notify({
          text: this.$larerror(e.response.data.errors, this.$t('cart.error_add_to_cart')),
          type: 'error',
        });
      });
    },
    async updateQuote(payload) {
      this.is_adding_item = false;

      if (this.$route.query.token)
        await this.$axios.put(`quotes/${this.$route.query.quote}/blinds/detach`, {token: this.$route.query.token})
            .then(({data}) => {
            })
            .catch(e => {
              this.$notify({
                title: this.$t('error'),
                text: this.$larerror(e.response.data, this.$t('quotes.error_remove_from_quote')),
                type: 'error',
              });
            });

      await this.$axios.put(`quotes/${this.$route.query.quote}/blinds/${this.$route.query.id}/attach`, payload).then(({data}) => {
        this.is_adding_item = false;
      }).catch(e => {
        this.is_adding_item = false;

        this.$notify({
          text: this.$larerror(e.response.data.errors, this.$t('quotes.error_add_to_quote')),
          type: 'error',
        });
      });
    },
    async getCart() {
      this.is_loading_cart = true;

      await this.$axios.get(`carts/${this.$store.getters.cart}`)
          .then(({data}) => {
            this.cart = data.data;
            this.is_loading_cart = false;
          })
          .catch(e => {
            this.is_loading_cart = false;

            this.$notify({
              title: this.$t('error'),
              text: this.$larerror(e.response.data, this.$t('cart.error_retrieve_cart')),
              type: 'error',
            });
          });
    },
    async addToCart() {
      this.$v.blind.$touch();
      this.$v.custom_colour_code.$touch();

      if (this.$v.blind.$anyError || this.$v.custom_colour_code.$anyError || this.is_adding_item)
        return;

      if (this.blind.width > 310) {
        this.$notify({
          text: 'Width cannot be larger than 310cm',
          type: 'error',
        });
        return;
      }

      if (this.blind.width <= 24) {
        this.$notify({
          text: 'Width cannot be smaller than 25cm',
          type: 'error',
        });
        return;
      }

      if (this.blind.drop <= 0) {
        this.$notify({
          text: 'Drop cannot be smaller than 1cm',
          type: 'error',
        });
        return;
      }

      if (!this.fabric_selected) {
        this.$notify({
          text: this.$t('calculators.fabric_required'),
          type: 'error',
        });
        return;
      }

      if (!this.colour_selected) {
        this.$notify({
          text: this.$t('calculators.fabric_colour_required'),
          type: 'error',
        });
        return;
      }

      if (this.$store.getters.cart)
        await this.getCart();

      let order = await this.getOrder();

      let payload = {
        order: order,
        note: this.note,
        value: this.totalCost,
        options: [
          {
            width: this.blind.width,
            drop: this.blind.drop,
            slat_qty: this.slatQtyUI,
            overlap_drop: this.blind.overlap_drop,
            overlap_width: this.blind.overlap_width,
            aperture_type: this.blind.aperture_type,
            recess_type: this.blind.recess_type,
            width_clearance: this.blind.width_clearance,
            cord_side: this.blind.cord_side,
            wand_side: this.blind.wand_side,
            tile_cut_width: this.blind.tile_cut_width,
            tile_cut_height: this.blind.tile_cut_height,
            tile_cut_width_clearance: this.blind.tile_cut_width_clearance,
            fabric_type: this.fabric_selected.attributes.name,
            fabric_colour: this.colour_selected,
          }
        ],
        is_unavailable: false
      }

      if (this.motor_selected)
        payload.options[0].motor = this.motor_selected.attributes.name;

      if (this.controller_type_selected)
        payload.options[0].controller_type = this.controller_type_selected.attributes.name;

      this.is_adding_item = true;

      if (this.$route.query.cart && this.$route.query.order) {
        await this.updateCartOrder(payload);

        this.$router.push({path: `/calculators/my-orders/${this.$route.query.order}/order-details`});

        return;
      }

      if (this.$route.query.quote) {
        await this.updateQuote(payload);

        if (this.$route.query.order) this.$router.push({path: `/calculators/my-orders/${this.$route.query.order}/order-details`});
        else this.$router.push({path: `/calculators/quotes/${this.$route.query.quote}/quote-details`});

        return;
      }

      if (!this.$store.getters.cart) {
        await this.$store.dispatch('createCart');
      }

      if (this.$route.query.token) {
        this.$axios.put(`carts/${this.$store.getters.cart}/blinds/detach`, {token: this.$route.query.token})
            .then(({data}) => {
            })
            .catch(e => {
              this.$notify({
                title: this.$t('error'),
                text: this.$larerror(e.response.data, this.$t('cart.error_remove_from_cart')),
                type: 'error',
              });
            });
      }

      if (!this.$route.query.token && !this.$route.query.quote && !this.$route.query.cart_order && this.blind_quantity > 1) {
        let tempBlinds = createArrayPayloadFromBlind(payload, this.blind_quantity)
        this.$axios.put(`carts/${this.$store.getters.cart}/blinds/${this.$route.query.id}/attach/many`, {blinds: tempBlinds}).then(({data}) => {

          this.$store.commit('SET_CART_COUNT', (Number(this.$store.getters.cartCount) + Number(this.blind_quantity)).toString())

          this.$notify({
            text: this.$t('cart.item_added_to_cart'),
            type: 'success',
          });

          this.blind = {
            width: null,
            drop: null,
            overlap_drop: null,
            overlap_width: null,
            aperture_type: null,
            recess_type: null,
            width_clearance: null,
            cord_side: null,
            wand_side: null,
            tile_cut_width: null,
            tile_cut_height: null,
            tile_cut_width_clearance: null
          }

          this.fabric_selected = null;
          this.colour_selected = null;
          this.custom_colour_code = null;
          this.note = null;
          this.blind_quantity = 1;

          this.motor_selected = null
          this.controller_type_selected = null;

          this.$v.blind.$reset();
          this.$v.custom_colour_code.$reset();

          this.is_adding_item = false;
        }).catch(e => {
          this.is_adding_item = false;

          this.$notify({
            text: this.$larerror(e.response.data.errors, this.$t('cart.error_add_to_cart')),
            type: 'error',
          });
        });
        return
      }

      this.$axios.put(`carts/${this.$store.getters.cart}/blinds/${this.$route.query.id}/attach`, payload).then(({data}) => {

        if (!this.$route.query.token)
          this.$store.commit('INCREMENT_CART_COUNT');

        if (this.$route.query.token) {
          this.$router.push({name: 'cart'});
        }

        this.$notify({
          text: this.$t('cart.item_added_to_cart'),
          type: 'success',
        });

        this.blind = {
          width: null,
          drop: null,
          overlap_drop: null,
          overlap_width: null,
          aperture_type: null,
          recess_type: null,
          width_clearance: null,
          cord_side: null,
          wand_side: null,
          tile_cut_width: null,
          tile_cut_height: null,
          tile_cut_width_clearance: null
        }

        this.fabric_selected = null;
        this.colour_selected = null;
        this.custom_colour_code = null;
        this.note = null;
        this.blind_quantity = 1;

        this.motor_selected = null
        this.controller_type_selected = null;

        this.$v.blind.$reset();
        this.$v.custom_colour_code.$reset();

        this.is_adding_item = false;
      }).catch(e => {
        this.is_adding_item = false;

        this.$notify({
          text: this.$larerror(e.response.data.errors, this.$t('cart.error_add_to_cart')),
          type: 'error',
        });
      });
    },
    populate() {
      if (this.$route.query.quote) {
        this.$axios.put(`quotes/${this.$route.query.quote}/blinds`, {token: this.$route.query.token})
            .then(({data}) => {
              const details = JSON.parse(data.options)[0];

              this.blind.width = details.width;
              this.blind.drop = details.drop;
              this.blind.aperture_type = details.aperture_type;
              this.blind.recess_type = details.recess_type;
              this.blind.width_clearance = details.width_clearance;
              this.blind.cord_side = details.cord_side;
              this.blind.wand_side = details.wand_side;

              this.blind.tile_cut_width = details.tile_cut_width;
              this.blind.tile_cut_height = details.tile_cut_height;


              if (details.overlap_width != null)
                this.blind.overlap_width = details.overlap_width;

              if (details.overlap_drop != null)
                this.blind.overlap_drop = details.overlap_drop;

              if (details.tile_cut_width_clearance)
                this.blind.tile_cut_width_clearance = details.tile_cut_width_clearance;

              if (data.note)
                this.note = data.note;

              this.fabrics.forEach(fabric => {
                if (fabric.attributes.name === details.fabric_type)
                  this.fabric_selected = fabric;
              })

              JSON.parse(this.fabric_selected.attributes.colors).forEach(colour => {
                if (colour === details.fabric_colour)
                  this.colour_selected = details.fabric_colour;
              })

              if (details.custom_colour_code)
                this.custom_colour_code = details.custom_colour_code;

              if (details.motor)
                this.motors.forEach(option => {
                  if (option.attributes.name === details.motor)
                    this.motor_selected = option;
                })

              if (details.controller_type)
                this.controller_types.forEach(option => {
                  if (option.attributes.name === details.controller_type)
                    this.controller_type_selected = option;
                })

            })
            .catch(e => {
              this.$notify({
                title: this.$t('error'),
                text: this.$larerror(e.response.data, this.$t('cart.error_remove_from_cart')),
                type: 'error',
              });
            });
      } else if (this.$route.query.cart) {
        this.$axios.put(`carts/${this.$route.query.cart}/blinds`, {token: this.$route.query.token})
            .then(({data}) => {
              const details = JSON.parse(data.options)[0];

              this.blind.width = details.width;
              this.blind.drop = details.drop;
              this.blind.aperture_type = details.aperture_type;
              this.blind.recess_type = details.recess_type;
              this.blind.width_clearance = details.width_clearance;
              this.blind.cord_side = details.cord_side;
              this.blind.wand_side = details.wand_side;

              this.blind.tile_cut_width = details.tile_cut_width;
              this.blind.tile_cut_height = details.tile_cut_height;

              if (details.overlap_width != null)
                this.blind.overlap_width = details.overlap_width;

              if (details.overlap_drop != null)
                this.blind.overlap_drop = details.overlap_drop;

              if (details.tile_cut_width_clearance)
                this.blind.tile_cut_width_clearance = details.tile_cut_width_clearance;

              if (data.note)
                this.note = data.note;

              this.fabrics.forEach(fabric => {
                if (fabric.attributes.name === details.fabric_type)
                  this.fabric_selected = fabric;
              })

              JSON.parse(this.fabric_selected.attributes.colors).forEach(colour => {
                if (colour === details.fabric_colour)
                  this.colour_selected = details.fabric_colour;
              })

              if (details.custom_colour_code)
                this.custom_colour_code = details.custom_colour_code;

              if (details.motor)
                this.motors.forEach(option => {
                  if (option.attributes.name === details.motor)
                    this.motor_selected = option;
                })

              if (details.controller_type)
                this.controller_types.forEach(option => {
                  if (option.attributes.name === details.controller_type)
                    this.controller_type_selected = option;
                })

            })
            .catch(e => {
              this.$notify({
                title: this.$t('error'),
                text: this.$larerror(e.response.data, this.$t('cart.error_remove_from_cart')),
                type: 'error',
              });
            });
      } else {
        this.$axios.put(`carts/${this.$store.getters.cart}/blinds`, {token: this.$route.query.token})
            .then(({data}) => {
              const details = JSON.parse(data.options)[0];

              this.blind.width = details.width;
              this.blind.drop = details.drop;
              this.blind.aperture_type = details.aperture_type;
              this.blind.recess_type = details.recess_type;
              this.blind.width_clearance = details.width_clearance;
              this.blind.cord_side = details.cord_side;
              this.blind.wand_side = details.wand_side;

              this.blind.tile_cut_width = details.tile_cut_width;
              this.blind.tile_cut_height = details.tile_cut_height;

              if (details.overlap_width != null)
                this.blind.overlap_width = details.overlap_width;

              if (details.overlap_drop != null)
                this.blind.overlap_drop = details.overlap_drop;

              if (details.tile_cut_width_clearance)
                this.blind.tile_cut_width_clearance = details.tile_cut_width_clearance;

              if (data.note)
                this.note = data.note;

              this.fabrics.forEach(fabric => {
                if (fabric.attributes.name === details.fabric_type)
                  this.fabric_selected = fabric;
              })

              JSON.parse(this.fabric_selected.attributes.colors).forEach(colour => {
                if (colour === details.fabric_colour)
                  this.colour_selected = details.fabric_colour;
              })

              if (details.custom_colour_code)
                this.custom_colour_code = details.custom_colour_code;

              if (details.motor)
                this.motors.forEach(option => {
                  if (option.attributes.name === details.motor)
                    this.motor_selected = option;
                })

              if (details.controller_type)
                this.controller_types.forEach(option => {
                  if (option.attributes.name === details.controller_type)
                    this.controller_type_selected = option;
                })

            })
            .catch(e => {
              this.$notify({
                title: this.$t('error'),
                text: this.$larerror(e.response.data, this.$t('cart.error_remove_from_cart')),
                type: 'error',
              });
            });
      }
    }
  },
  computed: {
    apertureWidthWithOverlap() {
      return (this.blind.width ? parseFloat(this.blind.width) : 0) + (this.blind.overlap_width ? parseFloat(this.blind.overlap_width) : 0)
    },
    apertureDropWithOverlap() {
      return (this.blind.drop ? parseFloat(this.blind.drop) : 0) + (this.blind.overlap_drop ? parseFloat(this.blind.overlap_drop) : 0)
    },
    slatQty() {
      return this.blind.drop / 2.155;
    },
    slatQtyUI() {
      return Math.round(this.slatQty).toString();
    },
    articlesCost() {
      let total = 0;

      if (this.motor_selected)
        total += this.motor_selected.attributes.price;

      if (this.controller_type_selected)
        total += this.controller_type_selected.attributes.price;

      return total;
    },
    blindCost() {
      let total = 0;

      this.per_cm_items.forEach(item => {
        if (item.attributes.on_width) {
          total += item.attributes.price * item.attributes.multiplier * this.apertureWidthWithOverlap;
        } else if (item.attributes.on_drop) {
          if (item.attributes.code === '23.860.001') { //Braided Ladder Tape
            if (this.blind.width > 0 && this.blind.width <= 85) {
              total += item.attributes.price * item.attributes.multiplier * this.blind.drop * 2;
            } else if (this.blind.width > 86 && this.blind.width <= 140) {
              total += item.attributes.price * item.attributes.multiplier * this.blind.drop * 3;
            } else if (this.blind.width > 140 && this.blind.width <= 195)
              total += item.attributes.price * item.attributes.multiplier * this.blind.drop * 4;
            else if (this.blind.width > 195 && this.blind.width <= 250)
              total += item.attributes.price * item.attributes.multiplier * this.blind.drop * 5;
          } else
            total += item.attributes.price * item.attributes.multiplier * this.blind.drop;
        }
      })

      this.per_unit_items.forEach(item => {
        if (item.attributes.code === '23.880.000') {
          if (this.blind.width > 0 && this.blind.width <= 85) {
            total += item.attributes.price * item.attributes.multiplier * 480;
          } else if (this.blind.width > 86 && this.blind.width <= 140) {
            total += item.attributes.price * item.attributes.multiplier * 535;
          } else if (this.blind.width > 140 && this.blind.width <= 195)
            total += item.attributes.price * item.attributes.multiplier * 1325;
          else if (this.blind.width > 195 && this.blind.width <= 250)
            total += item.attributes.price * item.attributes.multiplier * 1500;
        } else if (item.attributes.code === '29.337.000') {
          if (this.blind.width > 0 && this.blind.width <= 140) {
            total += item.attributes.price * item.attributes.multiplier * 2;
          } else if (this.blind.width > 140 && this.blind.width <= 250) {
            total += item.attributes.price * item.attributes.multiplier * 3;
          }
        } else {
          total += item.attributes.price * item.attributes.multiplier * item.attributes.quantity;
        }
      })

      this.per_hole_items.forEach(item => {
        if (this.blind.width > 0 && this.blind.width <= 85) {
          total += item.attributes.price * item.attributes.multiplier * 2;
        } else if (this.blind.width > 86 && this.blind.width <= 140) {
          total += item.attributes.price * item.attributes.multiplier * 3;
        } else if (this.blind.width > 140 && this.blind.width <= 195)
          total += item.attributes.price * item.attributes.multiplier * 4;
        else if (this.blind.width > 195 && this.blind.width <= 250)
          total += item.attributes.price * item.attributes.multiplier * 5;
      });

      //Extra Labour Charge
      if (this.blind.width > 0 && this.blind.width <= 38)
        total += 12;

      return total;
    },
    totalCostWithQuantity() {
      return this.blind_quantity * this.totalCost
    },
    fabricCost() {
      if (this.fabric_selected) {
        return this.fabric_selected.attributes.price * this.slatQty * this.apertureWidthWithOverlap * this.fabric_selected.attributes.multiplier;
      } else
        return 0;
    },
    totalCost() {
      if (this.apertureWidthWithOverlap && this.apertureDropWithOverlap) {
        return Math.ceil(this.blindCost + this.fabricCost + this.articlesCost);
      } else return 0;
    }
  },
  async mounted() {
    await this.retrieveBlind();
    await this.retrieveFabrics();
    await this.retrieveArticles();

    if (this.$route.query.token) this.populate();
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  @apply px-8 max-w-6xl mx-auto bg-white flex flex-col;

  & > .header-container {
    @apply flex flex-col sticky top-0 bg-white py-8;
    z-index: 99999;

    @screen lg {
      @apply flex-row
    }

    .title-container {
      @apply flex flex-row items-center;

      a {
        @apply text-primary text-2xl cursor-pointer;

        svg {

          &:hover {
            animation: spin 0.2s linear;
            animation-direction: reverse;

          }
        }
      }

      h1 {
        @apply font-bold text-center text-2xl ml-6;

        @screen lg {
          @apply text-3xl max-w-full ml-6;
        }
      }
    }

    .price-container {
      @apply flex flex-col items-center lg:mt-4;

      @screen lg {
        @apply mt-0 flex-row;
      }

      .price {
        @apply bg-white border-2 border-grey-light rounded py-2 w-full lg:w-5/12 lg:mr-8;

        @screen lg {
          width: -webkit-fit-content;
          width: -moz-fit-content;
          width: fit-content;
        }

        p {
          @apply text-center text-black text-xl font-bold ;
          white-space: nowrap;
        }
      }

      button {
        @apply w-full lg:w-7/12 mt-4 lg:mt-0 h-12;
      }
    }
  }

  & > .form-container {
    @apply bg-grey-form border-2 border-grey-form rounded-xl mb-8 p-6;

    @screen md {
      @apply p-8;
    }

    @screen lg {
      @apply p-10;
    }

    .form-title {
      @apply text-xl font-bold mb-8;
    }

    h2 {
      @apply text-sm text-black font-bold mb-8;
    }

    .form {
      @apply -mb-4;

      &.main-form {
        @apply mb-2;

        .input-group {
          .input {
            width: 50% !important;
            border-color: theme('colors.primary') !important;
          }
        }
      }
    }

    .divider {
      @apply mx-auto border-t-2 border-grey-light rounded-md my-8 w-full;

      @screen lg {
        @apply mx-auto;
      }
    }

    .form-wrapper {
      @apply flex flex-col w-full;

      @screen lg {
        @apply flex-row;
      }

      .textboxes-container {
        @apply flex w-full;

        @screen lg {
          @apply w-1/2 mr-1;
        }
      }

      .note-field {
        @apply w-full;

        @screen lg {
          @apply w-1/2 ml-1;
        }
      }
    }

    .custom-colour-field {
      @apply mb-0 mt-8 max-w-sm;
    }
  }

  & > .title {
    @apply font-bold my-4;
  }

  .item-container {
    @apply flex flex-row flex-wrap -m-4;

    .item-card-container {
      @apply w-1/2 p-4;

      @screen md {
        @apply w-1/4;
      }

      @screen lg {
        @apply w-1/5;
      }

      .item-card {
        @apply w-full bg-white rounded-xl flex flex-col h-24 max-w-full px-4 cursor-pointer border-white border-3;
        box-shadow: 0px 10px 15px #00000012;

        &.item-card-image {
          @apply h-auto py-4 px-6;

          & > img {
            @apply h-12 mt-4;
          }
        }

        &:hover {
          @apply border-primary;
        }

        &.selected {
          @apply border-primary;
        }

        .item-card-title {
          @apply text-center m-auto text-sm font-bold;

          @screen lg {
            @apply text-base;
          }
        }

        .price {
          @apply text-center m-auto text-sm font-bold;

          @screen lg {
            @apply text-base;
          }
        }
      }
    }
  }
}
</style>