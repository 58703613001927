export const measurementsOptions =
    [
        {"id": "back_bar", "name": "Back Bar", "value": 0},
        {"id": "brackets", "name": "Brackets", "value": 0},
        {"id": "bottom_bar", "name": "Bottom Bar", "value": 0},
        {"id": "fabric", "name": "Fabric", "value": 0},
        {"id": "fabric_cutting_clearance", "name": "Fabric Cutting Clearance", "value": 0},
        {"id": "alu_back_bar_cutting_clearance", "name": "Alu Back Bar Cutting Clearance", "value": 0},
        {"id": "alu_cover_cutting_clearance", "name": "Alu Cover Cutting Clearance", "value": 0},
        {"id": "top_tube_cutting_clearance", "name": "Top Tube Gear Cutting Clearance", "value": 0},
        {"id": "top_tube_batt_motor_cutting_clearance", "name": "Top Tube Batt Motor Cutting Clearance", "value": 0},
        {
            "id": "top_tube_electric_motor_cutting_clearance",
            "name": "Top Tube Electric Motor Cutting Clearance",
            "value": 0
        },
        {"id": "plastic_bottom_cutting_clearance", "name": "Plastic Bottom Cutting Clearance", "value": 0},
        {"id": "aluminium_bottom_cutting_clearance", "name": "Aluminium Bottom Cutting Clearance", "value": 0},
        {"id": "bottom_bar_fabric_wrap", "name": "Bottom Bar Fabric Wrap", "value": 0},
        {"id": "top_tube_fabric_wrap", "name": "Top Tube Fabric Wrap", "value": 0},
        {"id": "alum_outer_bottom_cutting_clearance", "name": "Alum Outer Bottom Cutting Clearance", "value": 0},
        {"id": "alum_inner_bottom_cutting_clearance", "name": "Alum Inner Bottom Cutting Clearance", "value": 0},
        {"id": "alum_rail_cutting_clearance", "name": "Alum Rail Cutting Clearance", "value": 0},
        {"id": "three_way_tilt_rod_cutting_length", "name": "3 Way Tilt Rod Cutting Length", "value": 0},
        {"id": "gear_and_top_tube", "name": "Gear / Top Tube", "value": 0},
        {"id": "cover", "name": "Cover", "value": 0},
    ]

export function returnMeasurements(item) {
    let fixingType = item?.attributes?.options[0]?.fixing_type
    const blindType = item.attributes.type
    const recessType = item.attributes.options[0].recess_type.toLowerCase().includes('outside') ? 'outside' : 'inside'
    const controlType = item?.attributes?.options?.[0]?.control_type ? item.attributes.options[0].control_type.toLowerCase().includes('cord') ? 'cord' : 'wand' : undefined
    const itemOptions = item.attributes.options[0]
    let tempMeasurements

    if (!item.attributes.measurements) return []

    if (fixingType && fixingType.includes('aluminium_back_bar')) {
        tempMeasurements = item.attributes.measurements.back_bar
    } else if (fixingType && fixingType.includes('brackets')) {
        tempMeasurements = item.attributes.measurements.brackets
    } else if (fixingType && fixingType.includes('aluminium_cover')) {
        tempMeasurements = item.attributes.measurements.cover
    } else if (controlType && controlType.includes('cord')) {
        tempMeasurements = item.attributes.measurements.cord
    } else if (controlType && controlType.includes('wand')) {
        tempMeasurements = item.attributes.measurements.wand
    } else if (!fixingType && item?.attributes?.measurements?.general) {
        tempMeasurements = item.attributes.measurements.general
    }

    if (!fixingType && !tempMeasurements && itemOptions?.aluminium_cover) {
        if (itemOptions.aluminium_cover.toLowerCase().includes('with cover')) {
            fixingType = 'aluminium_cover'
            tempMeasurements = item.attributes.measurements.cover
        } else if (itemOptions.aluminium_cover.toLowerCase().includes('without cover')) {
            fixingType = 'aluminium_back_bar'
            tempMeasurements = item.attributes.measurements.back_bar
        }
    }

    tempMeasurements = convertMeasurementsToObject(tempMeasurements)

    return returnPrintingArray(itemOptions, tempMeasurements, {fixingType, blindType, recessType, controlType})
}

function convertMeasurementsToObject(arr) {
    return arr.reduce((obj, item) => {
        obj[item.id] = item.value;
        return obj;
    }, {});
}

function returnRollerBlindMeasurementsOutside(options, measurements) {
    const printingArray = []

    const finalBlindArray = []
    if (options.width && measurements.overlap_width !== undefined) finalBlindArray.push({
        title: 'Final Blind Width',
        unit: 'cm',
        value: Number(options.width) + Number(measurements.overlap_width)
    })
    if (options.drop && measurements.overlap_drop !== undefined) finalBlindArray.push({
        title: 'Final Blind Drop',
        unit: 'cm',
        value: Number(options.drop) + Number(measurements.overlap_drop)
    })
    if (finalBlindArray.length) printingArray.push({width: 'two', values: finalBlindArray})

    const detailsArray = []
    if (measurements.gear_and_top_tube !== undefined) detailsArray.push({
        title: 'Gear / Top Tube',
        value: measurements.gear_and_top_tube
    })
    if (options.width && measurements.overlap_width !== undefined && measurements.brackets !== undefined) detailsArray.push({
        title: 'Top Tube Width',
        unit: 'cm',
        value: (Number(options.width) + Number(measurements.overlap_drop)) - Number(measurements.brackets)
    })
    if (detailsArray.length) printingArray.push({width: 'two', values: detailsArray})

    const aluminiumCuttingArray = []
    if (options.fixing_type.includes('aluminium_back_bar') && options.width && measurements.overlap_width !== undefined && measurements.back_bar !== undefined) aluminiumCuttingArray.push({
        title: 'Back-bar Width',
        unit: 'cm',
        value: (Number(options.width) + Number(measurements.overlap_drop)) - Number(measurements.back_bar)
    })
    if (options.fixing_type.includes('aluminium_cover') && options.width && measurements.overlap_width !== undefined && measurements.cover !== undefined) aluminiumCuttingArray.push({
        title: 'Cover Width',
        unit: 'cm',
        value: (Number(options.width) + Number(measurements.overlap_drop)) - Number(measurements.cover)
    })
    if (options.width && measurements.overlap_width !== undefined && measurements.bottom_bar !== undefined) aluminiumCuttingArray.push({
        title: 'Bottom Bar Width',
        unit: 'cm',
        value: (Number(options.width) + Number(measurements.overlap_drop)) - Number(measurements.bottom_bar)
    })
    if (aluminiumCuttingArray.length) printingArray.push({width: 'two', values: aluminiumCuttingArray})

    const fabricCuttingArray = []
    if (options.width && measurements.overlap_width !== undefined && measurements.fabric !== undefined) fabricCuttingArray.push({
        title: 'Fabric Cut Width',
        unit: 'cm',
        value: (Number(options.width) + Number(measurements.overlap_width)) - Number(measurements.fabric)
    })
    if (options.drop && measurements.overlap_drop !== undefined && measurements.top_tube_fabric_wrap !== undefined) fabricCuttingArray.push({
        title: 'Fabric Cut Drop',
        unit: 'cm',
        value: (Number(options.drop) + Number(measurements.overlap_drop)) + Number(measurements.top_tube_fabric_wrap)
    })
    if (fabricCuttingArray.length) printingArray.push({width: 'two', values: fabricCuttingArray})

    return printingArray
}

function returnRollerBlindMeasurementsInside(options, measurements) {
    const printingArray = []

    const finalBlindArray = []
    if (options.width && options.width_clearance !== undefined) finalBlindArray.push({
        title: 'Final Blind Width',
        unit: 'cm',
        value: Number(options.width) - Number(parseFloat(options.width_clearance))
    })
    if (options.drop) finalBlindArray.push({title: 'Final Blind Drop', unit: 'cm', value: Number(options.drop)})
    if (finalBlindArray.length) printingArray.push({width: 'two', values: finalBlindArray})

    const detailsArray = []
    if (measurements.gear_and_top_tube !== undefined) detailsArray.push({
        title: 'Gear / Top Tube',
        value: measurements.gear_and_top_tube
    })
    if (options.width && options.width_clearance !== undefined && measurements.brackets !== undefined) detailsArray.push({
        title: 'Top Tube Width',
        unit: 'cm',
        value: (Number(options.width) - Number(parseFloat(options.width_clearance))) - Number(measurements.brackets)
    })
    if (detailsArray.length) printingArray.push({width: 'two', values: detailsArray})

    const aluminiumCuttingArray = []
    if (options.fixing_type.includes('aluminium_back_bar') && options.width && options.width_clearance !== undefined && measurements.back_bar !== undefined) aluminiumCuttingArray.push({
        title: 'Back-bar Width',
        unit: 'cm',
        value: (Number(options.width) - Number(parseFloat(options.width_clearance))) - Number(measurements.back_bar)
    })
    if (options.fixing_type.includes('aluminium_cover') && options.width && options.width_clearance !== undefined && measurements.cover !== undefined) aluminiumCuttingArray.push({
        title: 'Cover Width',
        unit: 'cm',
        value: (Number(options.width) - Number(parseFloat(options.width_clearance))) - Number(measurements.cover)
    })
    if (options.width && options.width_clearance !== undefined && measurements.bottom_bar !== undefined) aluminiumCuttingArray.push({
        title: 'Bottom Bar Width',
        unit: 'cm',
        value: (Number(options.width) - Number(parseFloat(options.width_clearance))) - Number(measurements.bottom_bar)
    })
    if (aluminiumCuttingArray.length) printingArray.push({width: 'two', values: aluminiumCuttingArray})

    const fabricCuttingArray = []
    if (options.width && options.width_clearance !== undefined && measurements.fabric !== undefined) fabricCuttingArray.push({
        title: 'Fabric Cut Width',
        unit: 'cm',
        value: (Number(options.width) + Number(parseFloat(options.width_clearance))) - Number(measurements.fabric)
    })
    if (options.drop && measurements.top_tube_fabric_wrap !== undefined && measurements.bottom_bar_fabric_wrap !== undefined) fabricCuttingArray.push({
        title: 'Fabric Cut Drop',
        unit: 'cm',
        value: (Number(options.drop) + Number(measurements.top_tube_fabric_wrap)) + Number(measurements.bottom_bar_fabric_wrap)
    })
    if (fabricCuttingArray.length) printingArray.push({width: 'two', values: fabricCuttingArray})

    return printingArray
}

function returnPrintingArray(options, measurements, config) {
    const {fixingType, blindType, recessType, controlType} = config
    const printingArray = []

    const finalBlindArray = recessType === 'inside' ? returnInsideFinalBlindArray(options) : returnOutsideFinalBlindArray(options)
    if (finalBlindArray.length) printingArray.push({width: 'two', values: finalBlindArray})

    const aluminiumCuttingArray = returnAluminiumArray(options, measurements, blindType, recessType, fixingType)
    if (aluminiumCuttingArray.length) printingArray.push({width: 'two', values: aluminiumCuttingArray})

    if (blindType.includes('roller') || blindType.includes('zebra')) {
        const fabricArray = returnFabricArray(options, measurements, blindType, recessType, fixingType)
        if (fabricArray.length) printingArray.push({width: 'two', values: fabricArray})
    }

    if (controlType === 'cord' || blindType.includes('vertical')) {
        const cordArray = returnCordArray(options, blindType)
        if (cordArray.length) printingArray.push({width: 'two', values: cordArray})

        const chainArray = returnChainArray(options)
        if (chainArray.length) printingArray.push({width: 'two', values: chainArray})
    }

    if (controlType === 'wand') {
        const wandArray = returnWandArray(options, blindType)
        if (wandArray.length) printingArray.push({width: 'two', values: wandArray})
    }

    return printingArray
}

function removeNullValuesFromPrintingArray(arr) {
    return arr.map(x => ({...x, values: x.values.filter((y => y !== null))}))
}

function returnOutsideFinalBlindArray(options) {
    const finalBlindArray = []
    if (options.width != null && options.overlap_width != null) finalBlindArray.push({
        title: 'Final Blind Width',
        unit: 'cm',
        value: parseFloat(options.width) + parseFloat(options.overlap_width)
    })
    if (options.drop) finalBlindArray.push({
        title: 'Final Blind Drop',
        unit: 'cm',
        value: parseFloat(options.drop) + parseFloat(options.overlap_drop)
    })

    return finalBlindArray
}

function returnInsideFinalBlindArray(options) {
    const finalBlindArray = []
    if (options.width != null && options.width_clearance != null) finalBlindArray.push({
        title: 'Final Blind Width',
        unit: 'cm',
        value: parseFloat(options.width) - parseFloat(options.width_clearance)
    })
    if (options.drop) finalBlindArray.push({
        title: 'Final Blind Drop',
        unit: 'cm',
        value: parseFloat(options.drop)
    })

    return finalBlindArray
}

function returnAluminiumMeasurement(options, recessType, title, toSubtract) {
    return recessType.includes('outside') ? returnOutsideAluminiumCuttingMeasurement(options, title, toSubtract) : returnInsideAluminiumCuttingMeasurement(options, title, toSubtract)
}

function getBottomCuttingClearance(options, measurements) {
    if (options.bottom_bar && options.bottom_bar.toLowerCase().includes('pvc')) {
        return measurements.plastic_bottom_cutting_clearance
    }

    if (options.bottom_bar && options.bottom_bar.toLowerCase().includes('aluminium')) {
        return measurements.aluminium_bottom_cutting_clearance
    }
}

function getTopTubeCuttingClearance(options, measurements) {
    if (options.motor && options.motor.toLowerCase().includes('battery')) {
        return measurements.top_tube_batt_motor_cutting_clearance ? measurements.top_tube_batt_motor_cutting_clearance : 0
    } else if (options.motor && options.motor.toLowerCase().includes('electrical')) {
        return measurements.top_tube_electric_motor_cutting_clearance ? measurements.top_tube_electric_motor_cutting_clearance : 0
    } else {
        return measurements.top_tube_cutting_clearance
    }
}

function returnInsideZebraRollerBottomBar(options, measurements, blindType, recessType, fixingType, measurementType) {
    let bottomCuttingClearance
    let titleToReturn
    let bottomCuttingClearanceType

    bottomCuttingClearanceType = getBottomCuttingClearance(options, measurements)

    if (measurementType === 'roller') {
        bottomCuttingClearance = bottomCuttingClearanceType ? bottomCuttingClearanceType : measurements.plastic_bottom_cutting_clearance
        titleToReturn = 'Bottom Bar'
    } else if (measurementType === 'outer_bottom') {
        bottomCuttingClearance = measurements.alum_outer_bottom_cutting_clearance
        titleToReturn = 'Outer Bottom'
    }
    if (measurementType === 'inner_bottom') {
        bottomCuttingClearance = measurements.alum_inner_bottom_cutting_clearance
        titleToReturn = 'Inner Bottom'
    }

    let tempTileCutWidth = options.tile_cut_width ? options.tile_cut_width : 0
    let tempTileCutWidthClearance = options.tile_cut_width_clearance ? options.tile_cut_width_clearance : 0


    const G28 = parseFloat(tempTileCutWidth) - parseFloat(tempTileCutWidthClearance)
    const G29 = (parseFloat(options.width) - parseFloat(options.width_clearance)) - parseFloat(bottomCuttingClearance)
    let answer
    if ((parseFloat(tempTileCutWidth) - parseFloat(tempTileCutWidthClearance)) === 0 || (parseFloat(tempTileCutWidth) - parseFloat(tempTileCutWidthClearance)) > G29) {
        answer = G29;
    } else {
        answer = G28;
    }

    return ({
        title: titleToReturn,
        unit: 'cm',
        value: parseFloat(answer)
    })
}

function returnOutsideAluminiumCuttingMeasurement(options, title, toSubtract) {
    if (options.width == null || options.overlap_width == null || toSubtract == null) return null
    return ({
        title: title,
        unit: 'cm',
        value: parseFloat(options.width) + parseFloat(options.overlap_width) - parseFloat(toSubtract)
    })
}

function returnInsideAluminiumCuttingMeasurement(options, title, toSubtract) {
    if (options.width == null || options.width_clearance == null || toSubtract == null) return null
    return ({
        title: title,
        unit: 'cm',
        value: parseFloat(options.width) - parseFloat(options.width_clearance) - parseFloat(toSubtract)
    })
}

function returnAluminiumArray(options, measurements, blindType, recessType, fixingType) {
    const aluminiumArray = []
    if (blindType.includes('roller')) {
        if (measurements.gear_and_top_tube != null) {
            aluminiumArray.push({
                title: 'Gear / Top Tube',
                value: measurements.gear_and_top_tube
            })
            aluminiumArray.push({
                title: '',
                value: '',
            })
        }
        aluminiumArray.push(returnAluminiumMeasurement(options, recessType, 'Top Tube', getTopTubeCuttingClearance(options, measurements)))

        let bottomCuttingClearanceType
        bottomCuttingClearanceType = getBottomCuttingClearance(options, measurements)


        if (recessType.includes('inside')) aluminiumArray.push(returnInsideZebraRollerBottomBar(options, measurements, blindType, recessType, fixingType, 'roller'))
        else aluminiumArray.push(returnAluminiumMeasurement(options, recessType, 'Bottom Bar', bottomCuttingClearanceType ? bottomCuttingClearanceType : measurements.plastic_bottom_cutting_clearance))

        if (fixingType.includes('back')) aluminiumArray.push(returnAluminiumMeasurement(options, recessType, 'Alum Back Bar', measurements.alu_back_bar_cutting_clearance))
        if (fixingType.includes('cover')) aluminiumArray.push(returnAluminiumMeasurement(options, recessType, 'Alum Cover', measurements.alu_cover_cutting_clearance))
    }

    if (blindType.includes('zebra')) {
        if (measurements.gear_and_top_tube != null) {
            aluminiumArray.push({
                title: 'Gear / Top Tube',
                value: measurements.gear_and_top_tube
            })
            aluminiumArray.push({
                title: '',
                value: '',
            })
        }
        if (fixingType.includes('back')) aluminiumArray.push(returnAluminiumMeasurement(options, recessType, 'Alum Back Bar', measurements.alu_back_bar_cutting_clearance))
        if (fixingType.includes('cover')) aluminiumArray.push(returnAluminiumMeasurement(options, recessType, 'Alum Cover', measurements.alu_cover_cutting_clearance))
        aluminiumArray.push(returnAluminiumMeasurement(options, recessType, 'Top Tube', getTopTubeCuttingClearance(options, measurements)))

        if (recessType.includes('inside')) aluminiumArray.push(returnInsideZebraRollerBottomBar(options, measurements, blindType, recessType, fixingType, 'inner_bottom'))
        else aluminiumArray.push(returnAluminiumMeasurement(options, recessType, 'Inner Bottom', measurements.alum_inner_bottom_cutting_clearance))

        if (recessType.includes('inside')) aluminiumArray.push(returnInsideZebraRollerBottomBar(options, measurements, blindType, recessType, fixingType, 'outer_bottom'))
        else aluminiumArray.push(returnAluminiumMeasurement(options, recessType, 'Outer Bottom', measurements.alum_outer_bottom_cutting_clearance))
    }

    if (blindType.includes('vertical')) {
        aluminiumArray.push(returnAluminiumMeasurement(options, recessType, 'Alum Rail', measurements.alum_rail_cutting_clearance))
        aluminiumArray.push({
            title: '3-Way Tilt Rod',
            unit: 'cm',
            value: returnThreeWayTiltRod(options, measurements, recessType)
        })
    }

    if (blindType.includes('curtain')) {
        aluminiumArray.push(returnAluminiumMeasurement(options, recessType, 'Alum Rail', measurements.alum_rail_cutting_clearance))
        aluminiumArray.push({
            title: '3-Way Tilt Rod',
            unit: 'cm',
            value: returnThreeWayTiltRod(options, measurements, recessType)
        })
    }

    return aluminiumArray
}

function returnThreeWayTiltRod(options, measurements, recessType) {
    return recessType.includes('outside') ?
        parseFloat(options.width) + parseFloat(options.overlap_width) + parseFloat(measurements.three_way_tilt_rod_cutting_length) :
        (parseFloat(options.width) - parseFloat(options.width_clearance) - parseFloat(measurements.alum_rail_cutting_clearance)) + parseFloat(measurements.three_way_tilt_rod_cutting_length)
}

function returnInsideZebraRollerFabricWidth(options, measurements, blindType, recessType, fixingType) {
    let measurementTypeTemp
    if (blindType.includes('roller')) {
        measurementTypeTemp = 'roller'
    } else if (blindType.includes('zebra')) {
        measurementTypeTemp = 'outer_bottom'
    }

    const tempBottomBar = returnInsideZebraRollerBottomBar(options, measurements, blindType, recessType, fixingType, measurementTypeTemp)
    const tempBottomBarValue = tempBottomBar.value
    let bottomCuttingClearance
    let bottomCuttingClearanceType

    bottomCuttingClearanceType = getBottomCuttingClearance(options, measurements)

    if (blindType.includes('roller')) {
        bottomCuttingClearance = bottomCuttingClearanceType ? bottomCuttingClearanceType : measurements.plastic_bottom_cutting_clearance
    } else if (blindType.includes('zebra')) {
        bottomCuttingClearance = measurements.alum_outer_bottom_cutting_clearance
    }
    const G33 = (parseFloat(options.tile_cut_width) - parseFloat(options.tile_cut_width_clearance)) + parseFloat(bottomCuttingClearance) - parseFloat(measurements.fabric_cutting_clearance)
    const G34 = (parseFloat(options.width) - parseFloat(options.width_clearance)) - parseFloat(measurements.fabric_cutting_clearance)
    if (tempBottomBarValue === 0 || tempBottomBarValue > G34) {
        return G34;
    } else {
        return G33;
    }
}

function returnFabricArray(options, measurements, blindType, recessType, fixingType) {
    const fabricArray = []

    if ((blindType.includes('roller') || blindType.includes('zebra')) && recessType.includes('inside')) fabricArray.push({
        title: 'Fabric Cut Width',
        unit: 'cm',
        value: returnInsideZebraRollerFabricWidth(options, measurements, blindType, recessType, fixingType)
    })

    else fabricArray.push({
        title: 'Fabric Cut Width',
        unit: 'cm',
        value: recessType.includes('outside') ?
            (parseFloat(options.width) + parseFloat(options.overlap_width)) - parseFloat(measurements.fabric_cutting_clearance) :
            (parseFloat(options.width) - parseFloat(options.width_clearance)) - parseFloat(measurements.fabric_cutting_clearance)
    })

    let dropValue
    if (blindType.includes('roller')) {
        dropValue = recessType.includes('outside') ?
            parseFloat(options.drop) + parseFloat(options.overlap_drop) + parseFloat(measurements.top_tube_fabric_wrap) + parseFloat(measurements.bottom_bar_fabric_wrap) :
            parseFloat(options.drop) + parseFloat(measurements.top_tube_fabric_wrap) + parseFloat(measurements.bottom_bar_fabric_wrap)
    } else if (blindType.includes('zebra')) {
        dropValue = recessType.includes('outside') ?
            (parseFloat(options.drop) * 2) + parseFloat(options.overlap_drop) + parseFloat(measurements.top_tube_fabric_wrap) :
            (parseFloat(options.drop) * 2) + parseFloat(measurements.top_tube_fabric_wrap)
    }

    fabricArray.push({
        title: 'Fabric Cut Drop',
        unit: 'cm',
        value: dropValue
    })

    return fabricArray
}

function returnCordArray(options, blindType) {
    const apertureTypeSubtraction = options.aperture_type === 'door' ? 150 : 40
    const cordArray = []
    if (blindType.includes('vertical') || blindType.includes('curtain')) {
        cordArray.push({
            title: 'Slat Qty',
            value: options.slat_qty
        })
        if (options.runner_qty !== undefined && options.runner_qty !== null) {
            cordArray.push({
                title: 'Runner Qty',
                value: options.runner_qty
            })
        }
    }

    return cordArray
}

function returnChainArray(options) {
    const apertureTypeSubtraction = options.aperture_type === 'door' ? 150 : 40
    const chainArray = []
    chainArray.push({
        title: 'Cord',
        unit: 'cm',
        value: (parseFloat(options.width) * 2) + (parseFloat(options.drop) * 2) - parseFloat(apertureTypeSubtraction)
    })
    chainArray.push({
        title: 'Chain',
        unit: 'cm',
        value: (parseFloat(options.drop) * 2) - parseFloat(apertureTypeSubtraction)
    })

    return chainArray
}

function returnWandArray(options, blindType) {
    const apertureTypeWandValue = options.aperture_type === 'door' ? 150 : 100
    const wandArray = []
    if (blindType.includes('vertical') || blindType.includes('curtain')) {
        wandArray.push({
            title: 'Slat Qty',
            value: options.slat_qty
        })

        if (options.runner_qty !== undefined && options.runner_qty !== null) {
            wandArray.push({
                title: 'Runner Qty',
                value: options.runner_qty
            })
        }
    }
    wandArray.push({
        title: 'Wand',
        unit: 'cm',
        value: apertureTypeWandValue
    })

    return wandArray
}